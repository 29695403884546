import React from "react"
import { Link } from "gatsby"
import i18n, { resolveRoot } from "../i18n"

const Layout = ({ location, title, children, langKey }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath || location.pathname === rootPath + 'en'
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to={resolveRoot(langKey)}>{title}</Link>
        <span className="locale-links">
          {
            langKey === 'zh'
              ? <Link to={resolveRoot('en')}>English</Link>
              : <Link to={resolveRoot('zh')}>中文</Link>
          }
        </span>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to={ resolveRoot(langKey) }>
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()} Kenneth's handbook
      </footer>
    </div>
  )
}

export default Layout
