import en from './en'
import zh from './zh'

export const useTranslation = (langKey) => {
  const dict = langDict[langKey]
  return {
    t: (key) => dict[key]
  }
}

export const resolveRoot = (langKey) => {
  if (langKey === 'zh') {
    return '/'
  } else {
    return `/${langKey}`
  }
}

const langDict =  {
  en,
  zh
}

export default langDict